import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzFormModule } from "ng-zorro-antd/form";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzCollapseModule } from "ng-zorro-antd/collapse";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzIconModule } from "ng-zorro-antd/icon";
import { MarkRequiredModule } from "@app/admin/base/mark-required/module";
import { FormShipmentLocationBatch } from ".";
import { FormShipmentLocationBatchMultiPick } from "./multi-pick";
import { FormShipmentLocationBatchMultiDrop } from "./multi-drop";
import { FormShipmentLocationBatchMultiPickDrop } from "./multi-pick-drop";
import { FormShipmentLocationModule } from "../shipment-location/module";
import { BatchLocationSideIndicatorModule } from "./side-indicator/module";
import { FormOrderItemModule } from "../order-items/module";
import { FormShipmentLocationBatchLocationSummary } from "./location-summary";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzCollapseModule,
    NzSelectModule,
    NzButtonModule,
    NzIconModule,
    BatchLocationSideIndicatorModule,
    MarkRequiredModule,
    FormShipmentLocationModule,
    FormOrderItemModule,
  ],
  declarations: [
    FormShipmentLocationBatch,
    FormShipmentLocationBatchMultiPick,
    FormShipmentLocationBatchMultiDrop,
    FormShipmentLocationBatchMultiPickDrop,
    FormShipmentLocationBatchLocationSummary,
  ],
  exports: [
    FormShipmentLocationBatch,
  ]
})
export class FormShipmentLocationBatchModule {}