import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NzFormModule } from "ng-zorro-antd/form";
import { FormShipmentEntryBasicInfo } from ".";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { SelectBySearchingModule } from "@libs/select-client/module";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzInputModule } from "ng-zorro-antd/input";
import { FormShipmentEntryBasicTemplateDefault } from "./template-single";
import { RouterModule } from "@angular/router";
import { UiCommonModule } from "@app/admin/components/common/module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NzFormModule,
        ReactiveFormsModule,
        NzSelectModule,
        NzRadioModule,
        SelectBySearchingModule,
        NzCheckboxModule,
        NzInputModule,
        RouterModule,
        UiCommonModule,
    ],
    declarations: [
        FormShipmentEntryBasicInfo,
        FormShipmentEntryBasicTemplateDefault,
    ],
    exports: [
        FormShipmentEntryBasicInfo,
        FormShipmentEntryBasicTemplateDefault,
    ]
})
export class FormOrderBasicInfoModule {}