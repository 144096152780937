import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShipmentEntryDetail } from './detail';


const shipmentEntryRoutes: Routes = [
  { path: '', component: ShipmentEntryDetail },
];

@NgModule({
  imports: [RouterModule.forChild(shipmentEntryRoutes)],
  exports: [RouterModule]
})
export class ShipmentEntryRoutingModule { }
