import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ShipmentEntryContainer } from './container';
import { ShipmentEntryPreCreate } from './precreate';
import { ShipmentEntryInfoSummary } from './components/shipment-info';
import { ShipmentEntrySinglePickup } from './components/shipment-single/pickup';
import { ShipmentEntrySingleDelivery } from './components/shipment-single/delivery';
import { ShipmentEntryItems } from './components/shipment-single/items';
import { TabsModule } from '../base/tabs';
import { ShipmentTabs } from './components/shipment-tabs';
import { ShipmentInfoSingle } from './components/shipment-info/shipment-info-single';
import { CustomerAndEquipmentInfo } from './components/shipment-info/customer-equipment-info';
import { EmptyInfo } from './components/shipment-info/empty-info';
import { DeliveryInfoComponent } from './components/shipment-info/delivery-info';
import { ItemsInfo } from './components/shipment-info/items-info';
import { ShipmentActionInfo } from './components/shipment-info/actions-info';
import { FormShipmentLocationModule } from './components/forms/shipment-location/module';
import { FormSingleShipmentRevenue } from './components/forms/single-shipment-revenue';
import { FormBatchShipmentRevenue } from './components/forms/batch-shipment-revenue';
import { ShipmentEntrySingleRevenue } from './components/shipment-single/revenue';
import { ShipmentEntryBatchRevenue } from './components/shipment-batch/batch-revenue';
import { ShipmentEntrySingleDetail } from './components/shipment-detail-single';
import { ShipmentEntryBatchDetail } from './components/shipment-detail-batch';
import { ShipmentEntryDetail } from './detail';
import { SharedModule } from '@app/admin/shared/shared.module';
import { ShipmentEntryBatchLocationStops } from './components/shipment-batch/batch-location-stops';
import { ShipmentEntryBasicInfoBatch } from './components/shipment-batch/basic-info';
import { ShipmentEntryBasicInfo } from './components/shipment-single/basic-info';
import { FormOrderBasicInfoModule } from './components/forms/basic-info/module';
import { DetailSingleInfo } from './components/shipment-detail-single/detail-single-info';
import { DetailBatchInfo } from './components/shipment-detail-batch/detail-batch-info';
import { FormOrderItemModule } from './components/forms/order-items/module';
import { ItemList } from './components/shipment-detail-batch/route-detail/components/item-list';
import { RouteDetailBatch } from './components/shipment-detail-batch/route-detail';
import { ShipmentInfoBatch } from './components/shipment-info/shipment-info-batch';
import { BatchEquipmentInfo } from './components/shipment-detail-batch/batch-equipment-info';
import { EditShipmentEntryModule } from './components/edit-shipment/module';
import { FormInputCostV3Module } from './components/forms/input-cost-v3/module';
import { FormShipmentLocationBatchModule } from './components/forms/batch-locations/module';
import { DialogsModule } from '@dialogs/dialogs.module';
import { ShipmentEntryShareModule } from './share.module';
import { RefreshRouteLocationForMapService } from './components/shipment-detail-batch/route-detail/refresh-route-locations-service';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { ShipmentEntryRevenueInfoV2 } from './components/shipment-detail-batch/revenue-info-v2';
import { ShipmentEntryRevenueSummary } from './components/shipment-detail-batch/revenue-summary';
import { ViewContentItem } from './components/shipment-detail-batch/route-detail/components/view-content-item';
import { TransitNetworkModule } from '../transit-network/module';
import { ShipmentEntryDeliveryInfoSettings } from './components/shipment-detail-batch/route-detail/components/delivery-info-setting';
import { ModuleBookAppointment, ModuleUpdateTimeWindows } from '@wearewarp/ng-antd';
import { ShipmentEntryRoutingModule } from './shipmentEntry.route';
import { ThirdPartyInfoModule } from '../shipments/third-party-info/module';
import { WarehouseTaskSettingModule } from '../shipments/warehouse-task-setting/module';
import { PutOnHoldConfirmModule } from './components/forms/put-on-hold-confirm/module';
import { MapModule } from '../components/map/module';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { UiCommonModule } from '../components/common/module';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { ShipmentDetailService } from './detail/shipment-detail-service';
import { ModuleNotes } from '../components/notes/module';
import { OrderTagModule } from './components/forms/order-tags/module';
import { CreateByUserModule } from '../components/create-by-user/module';

@NgModule({
  imports: [
    CommonModule,
    ShipmentEntryRoutingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzIconModule,
    NzTableModule,
    NzButtonModule,
    SharedModule,
    TabsModule,
    DialogsModule,
    ShipmentEntryShareModule,
    FormShipmentLocationModule,
    FormShipmentLocationBatchModule,
    FormOrderBasicInfoModule,
    FormInputCostV3Module,
    FormOrderItemModule,
    EditShipmentEntryModule,
    NzModalModule,
    TransitNetworkModule,
    ModuleUpdateTimeWindows,
    ModuleBookAppointment,
    ThirdPartyInfoModule,
    WarehouseTaskSettingModule,
    PutOnHoldConfirmModule,
    MapModule,
    NzSpinModule,
    UiCommonModule,
    NzDividerModule,
    ModuleNotes,
    OrderTagModule,
    CreateByUserModule,
  ],
  declarations: [
    ShipmentEntryContainer,
    ShipmentEntryPreCreate,
    ShipmentEntryInfoSummary,
    ShipmentEntryBasicInfo,
    ShipmentEntrySinglePickup,
    ShipmentEntrySingleDelivery,
    ShipmentEntryItems,
    ShipmentTabs,
    ShipmentInfoSingle,
    EmptyInfo,
    CustomerAndEquipmentInfo,
    DeliveryInfoComponent,
    ItemsInfo,
    ShipmentActionInfo,
    FormSingleShipmentRevenue,
    FormBatchShipmentRevenue,
    ShipmentEntrySingleRevenue,
    ShipmentEntryBatchRevenue,
    ShipmentEntryBatchLocationStops,
    ShipmentEntryBasicInfoBatch,
    ShipmentEntrySingleDetail,
    ShipmentEntryBatchDetail,
    ShipmentEntryDetail,
    DetailSingleInfo,
    DetailBatchInfo,
    ItemList,
    RouteDetailBatch,
    DetailBatchInfo,
    ShipmentInfoBatch,
    BatchEquipmentInfo,
    ShipmentEntryRevenueInfoV2,
    ShipmentEntryRevenueSummary,
    ViewContentItem,
    ShipmentEntryDeliveryInfoSettings,
  ],
  exports: [
  ],
  providers: [
    RefreshRouteLocationForMapService,
    ShipmentDetailService,
  ]
})
export class ShipmentEntryModule {}